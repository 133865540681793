$sizes: 10px, 11px, 12px, 13px, 14px, 16px, 18px, 20px, 22px, 24px;

@each $size in $sizes {
  .font-size-#{$size} {
    font-size: $size !important;
  }
}

.font-weight-bold {
  font-weight: bold !important;
}

.ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 16px;
  max-height: 16px;
}

.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 16px;
  max-height: 32px;
}

.ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 16px;
  max-height: 48px;
}

.t-ellipsis {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}
