$input-bg-color: #efeff4;

.custom-input {
  .item-input-wrap {
    input {
      //border-radius: 30px;
      background: $input-bg-color;
      &::placeholder {
        color: #333;
        opacity: 0.8;
      }
    }
    &:after {
      content: none !important;
    }
  }
}

.custom-input-sa {
  border-radius: 12px;
  background: $input-bg-color;
  &::placeholder {
    color: #333;
    opacity: 0.8;
  }
}

.search-code-input {
  input {
    background: #e4e4e4;
    padding: 4px 12px;
    border-radius: 8px;
    &:after {
      content: none;
    }
  }
}
