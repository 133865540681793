/* Your app custom styles here */
//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');
@import url(https://fonts.googleapis.com/css?family=Muli:300,400,700,800);

@import "partials/helpers";
@import "partials/scrollbar";
@import "partials/decorators";
@import "partials/inputs";
@import "partials/typography";
@import "partials/animations";
@import "partials/custom";

/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Left Panel right border when it is visible by breakpoint */
.panel-left.panel-in-breakpoint:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}

/* Hide navbar link which opens left panel when it is visible by breakpoint */
.panel-left.panel-in-breakpoint ~ .view .navbar .panel-open[data-panel="left"] {
  display: none;
}

/*
  Extra borders for main view and left panel for iOS theme when it behaves as panel (before breakpoint size)
*/
.ios .panel-left:not(.panel-in-breakpoint).panel-in ~ .view-main:before,
.ios .panel-left:not(.panel-in-breakpoint).panel-closing ~ .view-main:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}

$main-bg-color-app: #FAFAFD;
$main-bg-color-app-rgb: 250, 250, 253;

:root {
  --f7-panel-width: 260px;
  --f7-font-family: Muli, -apple-system, SF Pro Text, SF UI Text, system-ui, Helvetica Neue, Helvetica, Arial, sans-serif;
  --f7-panel-backdrop-bg-color: rgba(0, 0, 0, 0.3);
  --f7-page-bg-color: #fafafd; // #ffffff; // #f3f3f3;
  --f7-theme-color: #333; // #007aff;
  --f7-theme-color-rgb: 20, 20, 20; // 0, 122, 255;
  --f7-theme-color-shade: #000; // #0066d6;
  --f7-theme-color-tint: #666; // #298fff;
  --f7-bars-bg-color: #fafafd; // #fff;
  --f7-bars-bg-color-rgb: 250, 250, 253; // 255, 255, 255;
  --f7-bars-bg-color-image: none;
  --f7-bars-border-color: transparent; // rgba(0, 0, 0, 0.25);
  --f7-bars-translucent-opacity: 1; // 0.8;
  --f7-bars-translucent-blur: 0px; // 20px;
  --f7-block-inset-border-radius: 0px; // 8px;
  --f7-list-inset-border-radius: 0px; // 8px;
  --f7-input-text-color: #000;
  --f7-grid-gap: 0px; // 16px;
  --f7-stepper-border-width: 1px; // 2px;
  --f7-chip-media-font-size: 14px; // 16px;
  --f7-chip-height: 22px; // 24px;
  --f7-sheet-bg-color: #fafafd; // #fff;
  --f7-inline-label-font-size: 14px;
  --f7-input-font-size: 14px; // 17px;
  --f7-block-title-font-size: 14px;
  --f7-list-font-size: 14px; // 17px;
  --f7-button-outline-border-width: 1px; // 2px;
}

.navbar-bg {
  opacity: 1 !important;
}

.disable-back-button {
  opacity: 0.5;
  pointer-events: none;
}

.tab-link {
  span {
    font-size: 14px;
  }
}

.tabbar-label {
  font-size: 14px;
}

.fix-web-app {
  width: 100vw;
  height: 100vh;
  //position: fixed;
}

.info-promo-popup {
  z-index: 100000 !important;
}

.color-transparent {
  color: transparent !important;
}

.z-index-999999 {
  z-index: 999999 !important;
  ~ .dialog-backdrop {
    z-index: 999998 !important;
  }
}

.dialog-backdrop {
  z-index: 10499;
}

$progress-height:                   1rem !default;
$progress-font-size:                (16 * .75) !default;
$progress-bg:                       lightgray !default;
$progress-border-radius:            4px !default;
$progress-box-shadow:               inset 0 .1rem .1rem rgba(black, .1) !default;
$progress-bar-color:                '#cacaca' !default;
$progress-bar-bg:                   goldenrod;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  font-size: $progress-font-size;
  background-color: $progress-bg;
  border-radius: $progress-border-radius;
  box-shadow: $progress-box-shadow;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $progress-bar-color;
  text-align: center;
  background-color: $progress-bar-bg;
  transition: $progress-bar-transition;
}

.factura_papel {
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
  background: darkgoldenrod;
  color: white;
}