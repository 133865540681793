.bill-lines {
  ul {
    li {
      height: 36px;
      min-height: 36px;
      max-height: 36px;
      .item-content {
        height: 36px;
        min-height: 36px;
        max-height: 36px;
        .item-inner {
          height: 36px;
          min-height: 36px;
          max-height: 36px;
          padding-top: 4px;
          padding-bottom: 4px;
          line-height: 1.1;
        }
      }
    }
  }
}