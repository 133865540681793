
.figure-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 5px;
  display: block;
  margin: 20px auto 20px auto;
  background-color: white;
  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    margin: 0;
    img {
      width: 100%;
    }
  }
}
