* {-webkit-overflow-scrolling: auto}

html.device-desktop {
  div.page-content::-webkit-scrollbar {width: 5px; height: 0;}
  div.page-content::-webkit-scrollbar-track {margin: 3px 0 3px}
  div.page-content::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.0);
    border-radius: 10px;
    box-shadow: rgba(255,255,255,0.3) 0 0 0 1px;
  }
  div.page-content:hover::-webkit-scrollbar-thumb {background: rgba(0,0,0,0.25)}
  div.page-content::-webkit-scrollbar-thumb:hover {background: rgba(0,0,0,0.35)}

  @media screen and (max-width: 480px) {
    div.page-content {right: 3px;}
    div.page-content::-webkit-scrollbar {width: 5px;}
  }
}
