/////// SHADOWS

.custom-elevation-0 {
  -webkit-box-shadow: 0px 0px 24px 4px #f3f3f3;
  box-shadow: 0px 0px 24px 4px #f3f3f3;
}

.custom-elevation-1 {
  -webkit-box-shadow: 0px 0px 10px 0px #f3f3f3;
  box-shadow: 0px 0px 10px 0px #f3f3f3;
}

/////// MENU COLORS

.menu-active {
  background: #333;
  color: white;
  a {
    color: white;
  }
}

////// LINKS

.main-menu-link {
  flex: 1;
  justify-content: flex-start;
}

/////// RIBBONS

.ribbon-box {
  position: absolute;
  top: 0.25rem;
  left: -16px;
  line-height: 1.4;
  height: 1.4rem;
  padding: 0 .25rem;
  z-index: 5;
  font-weight: 600;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.ribbon-box-card {
  position: absolute;
  line-height: 1.4;
  height: 1.4rem;
  padding: 0 .25rem;
  z-index: 5;
  font-weight: 600;
  bottom: 0.25rem;
  right: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.ribbon-primary {
  color: #fff;
  background-color: #5179d6;
}

/// FIXEDS

[aria-hidden="true"] { visibility: hidden; }


.bg-green {
  background-color: transparentize(#b0f542, 0.7) !important;
  //transition: background-color ease-in 0.25s;
}

.bg-red {
  background-color: transparentize(red, 0.7) !important;
}

.bg-purple {
  background-color: transparentize(#dc9dfc, 0.7) !important;
}

.bg-yellow {
  background-color: transparentize(#f5da42, 0.7) !important;
}

.opacity-half {
  opacity: 0.6;
  background-color:  transparentize(#9de4fc, 0.7) !important;
}

.bg-blue {
  background-color:  transparentize(#9de4fc, 0.9) !important;
}

//i.icon.lab.la-telegram {
//  color: #0088cc;
//}
//
//i.icon.lab.la-whatsapp {
//  color: #128c7e;
//}

.noselect {
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.select-all {
  -webkit-user-select: all; /* Safari */
  -khtml-user-select: all; /* Konqueror HTML */
  -moz-user-select: all; /* Old versions of Firefox */
  -ms-user-select: all; /* Internet Explorer/Edge */
  user-select: all; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}