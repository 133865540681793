
#view-popup {
  @media (max-width: 630px) {
    width: 100%;
    height: 100%;
  }
  @media (min-height: 630px) and (min-width: 630px) {
    width: 90%;
    height: 90%;
    top: 0;
    left: 0;
    margin-left: 5%;
    margin-top: 5%;
  }
}
